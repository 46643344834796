var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.hideText)?_c('div',{staticClass:"text-container",class:`${_vm.textPosition}`,style:({
    'text-align': `${_vm.textOptions.placement}`,
    'border-radius': `${_vm.borderRadius}`,
    background: `${_vm.background}`,
  })},[_c('h3',{staticClass:"player-text",style:({
      color: `${_vm.textOptions.color}`,
      'font-size': `${_vm.textOptions.size}px`,
      'line-height': `${_vm.textOptions.size}px`,
      'font-weight': `${_vm.isBold}`,
      'font-style': `${_vm.isItalic}`,
      'text-decoration': `${_vm.isUnderlined}`,
    })},[_vm._v(" "+_vm._s(_vm.decodeTextPlayer(_vm.textOptions.text))+" ")])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }